import React from 'react'
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../layouts/index"
import Seo from "../components/seo"

const LegalsPage = (props) => {

  const data = useStaticQuery(graphql`
  query LegalsPageQuery {
    contentfulPage(slug: {eq: "/mentions-legales/"}) {
      title
      description {
        description
      }
      keywords
      pageContent {
        ... on ContentfulSimplePageContent {
          __typename
          id
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
  `)

  const pageContent = data.contentfulPage.pageContent.find(content => content.__typename === "ContentfulSimplePageContent");

  return (
    <Layout>
      <Seo
        title={data.contentfulPage.title}
        description={data.contentfulPage.description.description}
        keywords={data.contentfulPage.keywords} />
      <section className="common-wrapper mt-10 px-15 px-4-md pb-10">
        <div className="flex justify-between pt-5">
          <div className="text-align-left w-half">
            <div className="block f4 font-normal lh-4 text-blue-dark" dangerouslySetInnerHTML={{ __html: pageContent.description.childMarkdownRemark.html }}></div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default LegalsPage